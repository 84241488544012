export const buildLinkPath = (slug: string, contentType: string) => {
    if(slug === 'home') {
        return '/';
    }

    switch(contentType) {
    case 'blogPost':
        return `/blog/${slug}`;
    case 'websitePage':
    default:
        return `/${slug}`;
    }
};